// src/components/CompletedJobs.jsx

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';
import '../AdminDashboard.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress'; // 1. Import CircularProgress
import Box from '@mui/material/Box'; // 2. Import Box

const CompletedJobs = () => {
    const [searchFields, setSearchFields] = useState({
        WorkOrder: '',
        ClientWorkOrder: '',
        ClientPurchaseOrder: '',
        JobAddress: '',
        Account: ''
    });

    const [workOrders, setWorkOrders] = useState([]);
    const [programmedWorkOrders, setProgrammedWorkOrders] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [filteredProgrammedRows, setFilteredProgrammedRows] = useState([]);
    const [error, setError] = useState('');

    // **1. New State Variable for User Email**
    const [userEmail, setUserEmail] = useState('');
    const [isRestrictedUser, setIsRestrictedUser] = useState(false);

    // **2. New State Variables for Loading Indicators**
    const [loadingProgrammedCompletedJobs, setLoadingProgrammedCompletedJobs] = useState(false);
    const [loadingOtherClientsCompletedJobs, setLoadingOtherClientsCompletedJobs] = useState(false);

    useEffect(() => {
        // **3. Retrieve User Email from localStorage on Component Mount**
        const email = localStorage.getItem('email');
        setUserEmail(email || '');
    }, []);

    useEffect(() => {
        // **4. Determine if the User is Restricted**
        const restrictedEmail = 'sgrehanmanagement@gmail.com';
        setIsRestrictedUser(userEmail.toLowerCase() === restrictedEmail.toLowerCase());

        // **5. Fetch Data for Completed Jobs**
        fetchDataForCompletedJobs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userEmail]);

    const fetchDataForCompletedJobs = async () => {
        // Fetch Programmed Completed Jobs
        await fetchProgrammedCompletedJobsData();

        // If user is not restricted, fetch Other Clients Completed Jobs
        if (!isRestrictedUser) {
            await fetchOtherClientsCompletedJobsData();
        }
    };

    const fetchProgrammedCompletedJobsData = async () => {
        setLoadingProgrammedCompletedJobs(true);
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/status/COMPLETED_JOB`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const programmedData = await response.json();
                console.log('Fetched Completed Programmed Work Orders:', programmedData);
                setProgrammedWorkOrders(programmedData);
                setFilteredProgrammedRows(programmedData);
            } else {
                console.error('Failed to fetch completed programmed work orders');
                setProgrammedWorkOrders([]);
                setFilteredProgrammedRows([]);
            }
        } catch (error) {
            console.error('Error fetching completed programmed work orders:', error);
            setProgrammedWorkOrders([]);
            setFilteredProgrammedRows([]);
            setError('Error fetching completed programmed work orders');
        } finally {
            setLoadingProgrammedCompletedJobs(false);
        }
    };

    const fetchOtherClientsCompletedJobsData = async () => {
        setLoadingOtherClientsCompletedJobs(true);
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/work-orders/status/COMPLETED_JOB`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Fetched Completed Work Orders:', data);
                // **Filter out programmed-facility-management accounts**
                const filteredData = data.filter(order => order.account !== 'programmed-facility-management');
                setWorkOrders(filteredData);
                setFilteredRows(filteredData);
            } else {
                console.error('Failed to fetch completed work orders');
                setWorkOrders([]);
                setFilteredRows([]);

            }
        } catch (error) {
            console.error('Error fetching completed work orders:', error);
            setWorkOrders([]);
            setFilteredRows([]);
            setError('Error fetching completed work orders');
        } finally {
            setLoadingOtherClientsCompletedJobs(false);
        }
    };

    useEffect(() => {
        // **6. Filter Programmed Work Orders based on searchFields**
        setFilteredProgrammedRows(programmedWorkOrders.filter(row =>
            row.workOrderNumber.toLowerCase().includes(searchFields.WorkOrder.toLowerCase()) &&
            row.jobNumber.toLowerCase().includes(searchFields.ClientWorkOrder.toLowerCase()) &&
            row.poNumber.toLowerCase().includes(searchFields.ClientPurchaseOrder.toLowerCase()) &&
            row.address.toLowerCase().includes(searchFields.JobAddress.toLowerCase()) &&
            (row.contactName ? row.contactName.toLowerCase().includes(searchFields.Account.toLowerCase()) : true)
        ));
    }, [searchFields, programmedWorkOrders]);

    useEffect(() => {
        // **7. Filter Other Clients Work Orders based on searchFields**
        setFilteredRows(workOrders.filter(row =>
            row.workOrderNumber.toLowerCase().includes(searchFields.WorkOrder.toLowerCase()) &&
            row.jobNumber.toLowerCase().includes(searchFields.ClientWorkOrder.toLowerCase()) &&
            row.poNumber.toLowerCase().includes(searchFields.ClientPurchaseOrder.toLowerCase()) &&
            row.address.toLowerCase().includes(searchFields.JobAddress.toLowerCase()) &&
            (row.account ? row.account.toLowerCase().includes(searchFields.Account.toLowerCase()) : true)
        ));
    }, [searchFields, workOrders]);

    const handleSearchChange = (e) => {
        setSearchFields({ ...searchFields, [e.target.name]: e.target.value });
    };

    const clearFilters = () => {
        setSearchFields({
            WorkOrder: '',
            ClientWorkOrder: '',
            ClientPurchaseOrder: '',
            JobAddress: '',
            Account: ''
        });
    };

    const handleStatusChange = async (workOrderNumber, isProgrammed) => {
        try {
            const token = localStorage.getItem('token');
            const apiUrl = isProgrammed
                ? `${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/status/${workOrderNumber}`
                : `${process.env.REACT_APP_API_URL}/admin/work-orders/status/${workOrderNumber}`;

            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: 'NEW' }),
            });

            if (!response.ok) {
                throw new Error('Failed to update status');
            }

            // Refresh the data after status change
            fetchDataForCompletedJobs();
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    return (
        <>
            <AdminHeader />
            <br />
            <div className="search-fields" style={{ marginTop: '20px' }}>
                <TextField
                    className="search-field"
                    name="WorkOrder"
                    value={searchFields.WorkOrder}
                    onChange={handleSearchChange}
                    label="Work Order Number"
                    variant="outlined"
                />
                <TextField
                    className="search-field"
                    name="ClientWorkOrder"
                    value={searchFields.ClientWorkOrder}
                    onChange={handleSearchChange}
                    label="Client Work Order Number"
                    variant="outlined"
                />
                <TextField
                    className="search-field"
                    name="ClientPurchaseOrder"
                    value={searchFields.ClientPurchaseOrder}
                    onChange={handleSearchChange}
                    label="Client Purchase Order Number"
                    variant="outlined"
                />
                <TextField
                    className="search-field"
                    name="JobAddress"
                    value={searchFields.JobAddress}
                    onChange={handleSearchChange}
                    label="Job Address"
                    variant="outlined"
                />
                <TextField
                    className="search-field"
                    name="Account"
                    value={searchFields.Account}
                    onChange={handleSearchChange}
                    label="Account"
                    variant="outlined"
                />
            </div>
            <div>
                <button className="clear-filter" onClick={clearFilters}>Clear Filters</button>
            </div>
            {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}

            {/* Programmed Completed Jobs Table */}
            <h2 style={{ textAlign: 'center' }}>Programmed Facility Management - Completed Work Orders</h2>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="completed programmed jobs table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">PO&nbsp;Received</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Work&nbsp;Order</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Job&nbsp;Address</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left" className="table-cell-wrap">Details&nbsp;of&nbsp;Incident/Event</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Site&nbsp;Contact&nbsp;Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Site&nbsp;Contact&nbsp;No</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Target&nbsp;Finish</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="center">Move Back to Workorder</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredProgrammedRows.map((row, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { borderBottom: '1px solid rgba(224, 224, 224, 1)' } }}>
                                <TableCell component="th" scope="row">
                                    {new Date(row.dateTime).toLocaleString()}
                                </TableCell>
                                <TableCell align="left">
                                    <Link to={`/admin/programmed-work-orders/${row.workOrderNumber}`}>
                                        {row.workOrderNumber}
                                    </Link>
                                </TableCell>
                                <TableCell align="left" className="table-cell-wrap">{row.address}</TableCell>
                                <TableCell align="left" className="table-cell-wrap">{row.detailsOfIncident}</TableCell>
                                <TableCell align="left">{row.contactName}</TableCell>
                                <TableCell align="left">{row.contactPhone}</TableCell>
                                <TableCell align="left">{new Date(row.targetFinish).toLocaleString()}</TableCell>
                                <TableCell align="center">
                                    <Button
                                        variant="contained"
                                        onClick={() => handleStatusChange(row.workOrderNumber, true)}
                                        sx={{
                                            backgroundColor: '#005B5B',  // Dark Green
                                            color: 'white',
                                            padding: '10px 20px',
                                            textTransform: 'none',
                                            borderRadius: '8px',
                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                            '&:hover': {
                                                backgroundColor: '#218838',  // Lighter Green on hover
                                            },
                                        }}
                                    >
                                        Move Back to Workorder
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* CircularProgress for Programmed Completed Jobs */}
            <Box display="flex" justifyContent="center" mt={2}>
                {loadingProgrammedCompletedJobs && <CircularProgress />}
            </Box>

            <br /><br /><br />

            {/* **5. Conditionally Render "Other Completed Jobs" Section** */}
            {!isRestrictedUser && (
                <>
                    <h2 style={{ textAlign: 'center' }}>Other Clients - Completed Work Orders</h2>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="completed other jobs table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">PO&nbsp;Received</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Work&nbsp;Order</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Job&nbsp;Address</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Account</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Client&nbsp;Work&nbsp;Order&nbsp;(No.)</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Client&nbsp;Purchase&nbsp;Order&nbsp;(No.)</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Site&nbsp;Contact&nbsp;-&nbsp;Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Site Contact&nbsp;-&nbsp;Contact&nbsp;Number</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Target&nbsp;Finish</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="center">Move Back to Workorder</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredRows.map((row, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { borderBottom: '1px solid rgba(224, 224, 224, 1)' } }}>
                                        <TableCell component="th" scope="row">
                                            {new Date(row.dateTime).toLocaleString()}
                                        </TableCell>
                                        <TableCell align="left">
                                            <Link to={`/admin/work-orders/${row.workOrderNumber}`}>
                                                {row.workOrderNumber}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="left" className="table-cell-wrap">{row.address}</TableCell>
                                        <TableCell align="left">{row.account}</TableCell>
                                        <TableCell align="left">{row.jobNumber}</TableCell>
                                        <TableCell align="left">{row.poNumber}</TableCell>
                                        <TableCell align="left">{row.contactName}</TableCell>
                                        <TableCell align="left">{row.contactMobile}</TableCell>
                                        <TableCell align="left">{new Date(row.targetFinish).toLocaleString()}</TableCell>
                                        <TableCell align="center">
                                            <Button
                                                variant="contained"
                                                onClick={() => handleStatusChange(row.workOrderNumber, false)}
                                                sx={{
                                                    backgroundColor: '#005B5B',  // Dark Green
                                                    color: 'white',
                                                    padding: '10px 20px',
                                                    textTransform: 'none',
                                                    borderRadius: '8px',
                                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                                    '&:hover': {
                                                        backgroundColor: '#218838',  // Lighter Green on hover
                                                    },
                                                }}
                                            >
                                                Move Back to Workorder
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* CircularProgress for Other Clients Completed Jobs */}
                    <Box display="flex" justifyContent="center" mt={2}>
                        {loadingOtherClientsCompletedJobs && <CircularProgress />}
                    </Box>
                </>
            )}

            <br /><br /><br />
            <p className="centered-text">Having issues? Contact IT Technical Support on <span className="phone-number">0405 236 236</span></p>
            <AdminFooter />
        </>
    );
};

export default CompletedJobs;
