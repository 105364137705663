// src/components/AdminDashboard.jsx

import React, { useEffect, useState } from 'react';
import AdminHeader from './AdminHeader';
import AdminSummaryPanel from './AdminSummaryPanel';
import AdminFooter from './AdminFooter';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import classNames from 'classnames';
import './AdminDashboard.css';

const AdminDashboard = () => {
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Existing state variables
  const [workOrderCount, setWorkOrderCount] = useState(0);
  const [programmedWorkOrderCount, setProgrammedWorkOrderCount] = useState(0);
  const [purchaseOrderCount, setPurchaseOrderCount] = useState(0);
  const [readyToInvoiceCount, setReadyToInvoiceCount] = useState(0);
  const [programmedReadyToInvoiceCount, setProgrammedReadyToInvoiceCount] = useState(0);
  const [completedJobsCount, setCompletedJobsCount] = useState(0);
  const [programmedCompletedJobsCount, setProgrammedCompletedJobsCount] = useState(0);

  const [userEmail, setUserEmail] = useState(''); // State to store user email

  const navigate = useNavigate(); // Initialize navigate function

  // Function to fetch paged data
  const fetchPagedData = async (url) => {
    const token = localStorage.getItem('token');
    let allData = [];
    let page = 0;
    let hasMore = true;

    while (hasMore) {
      try {
        const response = await fetch(`${url}?page=${page}&limit=20`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          allData = allData.concat(data.content); // Assuming `content` holds the paginated data
          hasMore = data.content.length > 0; // Stop if no more records are returned
          page++;
        } else {
          console.error(`Failed to fetch data from ${url}`);
          hasMore = false;
        }
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        hasMore = false;
      }
    }

    return allData;
  };

  // Fetch Purchase Order Numbers
  const fetchPurchaseOrderNumbers = async () => {
    try {
      let unpaidPurchaseOrderCount = 0;

      // Fetch and count unpaid regular purchase orders
      const regularPurchaseOrders = await fetchPagedData(`${process.env.REACT_APP_API_URL}/api/purchase-orders`);
      const unpaidRegularPurchaseOrders = regularPurchaseOrders.filter(order => order.paymentStatus === 'UNPAID');
      unpaidPurchaseOrderCount += unpaidRegularPurchaseOrders.length;

      // Fetch and count unpaid programmed purchase orders
      const programmedPurchaseOrders = await fetchPagedData(`${process.env.REACT_APP_API_URL}/api/purchase-orders/programmed`);
      const unpaidProgrammedPurchaseOrders = programmedPurchaseOrders.filter(order => order.paymentStatus === 'UNPAID');
      unpaidPurchaseOrderCount += unpaidProgrammedPurchaseOrders.length;

      setPurchaseOrderCount(unpaidPurchaseOrderCount);
    } catch (error) {
      console.error('Error fetching purchase order numbers:', error);
      setError('Failed to fetch purchase order numbers.');
    }
  };

  // Fetch Work Order Numbers
  const fetchWorkOrderNumbers = async () => {
    const token = localStorage.getItem('token');

    try {
      // Fetch regular work orders by status NEW
      const response1 = await fetch(`${process.env.REACT_APP_API_URL}/admin/work-orders/status/NEW`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response1.ok) {
        const data1 = await response1.json();
        setWorkOrderCount(data1.length);
      } else {
        console.error('Failed to fetch regular work orders by status NEW');
      }

      // Fetch programmed work orders by status NEW
      const response2 = await fetch(`${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/status/NEW`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response2.ok) {
        const data2 = await response2.json();
        setProgrammedWorkOrderCount(data2.length);
      } else {
        console.error('Failed to fetch programmed work orders by status NEW');
      }

    } catch (error) {
      console.error('Error fetching work orders:', error);
      setError('Failed to fetch work orders.');
    }
  };

  // Fetch Ready to Invoice Numbers
  const fetchReadyToInvoiceNumbers = async () => {
    const token = localStorage.getItem('token');

    try {
      // Fetch regular work orders by status READY_TO_INVOICE
      const response1 = await fetch(`${process.env.REACT_APP_API_URL}/admin/work-orders/status/READY_TO_INVOICE`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response1.ok) {
        const data1 = await response1.json();
        setReadyToInvoiceCount(data1.length);
      } else {
        console.error('Failed to fetch regular work orders by status READY_TO_INVOICE');
      }

      // Fetch programmed work orders by status READY_TO_INVOICE
      const response2 = await fetch(`${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/status/READY_TO_INVOICE`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response2.ok) {
        const data2 = await response2.json();
        setProgrammedReadyToInvoiceCount(data2.length);
      } else {
        console.error('Failed to fetch programmed work orders by status READY_TO_INVOICE');
      }

    } catch (error) {
      console.error('Error fetching ready-to-invoice work orders:', error);
      setError('Failed to fetch ready-to-invoice work orders.');
    }
  };

  // Fetch Completed Jobs Numbers
  const fetchCompletedJobsNumbers = async () => {
    const token = localStorage.getItem('token');

    try {
      // Fetch regular work orders by status COMPLETED_JOB
      const response1 = await fetch(`${process.env.REACT_APP_API_URL}/admin/work-orders/status/COMPLETED_JOB`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response1.ok) {
        const data1 = await response1.json();
        setCompletedJobsCount(data1.length);
      } else {
        console.error('Failed to fetch regular completed jobs');
      }

      // Fetch programmed work orders by status COMPLETED_JOB
      const response2 = await fetch(`${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/status/COMPLETED_JOB`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response2.ok) {
        const data2 = await response2.json();
        setProgrammedCompletedJobsCount(data2.length);
      } else {
        console.error('Failed to fetch programmed completed jobs');
      }

    } catch (error) {
      console.error('Error fetching completed jobs:', error);
      setError('Failed to fetch completed jobs.');
    }
  };

  // useEffect to fetch all data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve user email from localStorage
        const email = localStorage.getItem('email');
        setUserEmail(email || ''); // Ensure it's a string

        await Promise.all([
          fetchWorkOrderNumbers(),
          fetchReadyToInvoiceNumbers(),
          fetchPurchaseOrderNumbers(),
          fetchCompletedJobsNumbers(),
        ]);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setError('Failed to load dashboard data.');
      } finally {
        setIsLoading(false); // Hide loading screen after all fetches
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Define the restricted email
  const restrictedEmail = 'sgrehanmanagement@gmail.com';

  // Check if the current user is restricted
  const isRestrictedUser = userEmail.toLowerCase() === restrictedEmail.toLowerCase();

  // Conditional Rendering based on loading and error state
  if (isLoading) {
    // Loading Screen
    return (
      <div className="loading-screen">
        <img src="/Black Logo.png" alt="" className="loading-logo" />
      </div>
    );
  }

  if (error) {
    // Error Screen with two buttons: Retry and Navigate to Dashboard
    return (
      <div className="error-screen">
        <p>{error}</p>
        <div className="error-buttons"> {/* Container for buttons */}
          <button onClick={() => { setIsLoading(true); setError(null); window.location.reload(); }}>
            Retry
          </button>
          <button
            className="navigate-button" // Assign a new class for styling
            onClick={() => navigate('/admin/dashboard')} // Navigate to dashboard route
          >
            Navigate to Dashboard
          </button>
        </div>
      </div>
    );
  }

  // Main Dashboard Content
  return (
    <div
      className="admin-dashboard"
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        opacity: isLoading ? 0 : 1,
        transition: 'opacity 0.5s ease-in-out',
      }}
    >
      <div style={{ flex: '1 0 auto' }}>
        <AdminHeader />
        <div className="welcome-banner">Welcome to Antillia Admin</div>
        <div className="summary-container">
          <Link to="/admin/work-orders" className={classNames('dashboard-item', 'link-button')}>
            <img src="/WO Logo.jpg" alt="Work order logo" className="dashboard-logo" />
            <AdminSummaryPanel title="Work Orders" count={workOrderCount + programmedWorkOrderCount} />
          </Link>
          <Link to="/admin/ready-to-invoice" className={classNames('dashboard-item', 'link-button')}>
            <img src="/Invoice Logo.png" alt="Invoice logo" className="dashboard-logo" />
            <AdminSummaryPanel title="Ready to Invoice" count={readyToInvoiceCount + programmedReadyToInvoiceCount} />
          </Link>
          <Link to="/admin/completed-jobs" className={classNames('dashboard-item', 'link-button')}>
            <img src="/Complete Logo.png" alt="Completed jobs logo" className="dashboard-logo" />
            <AdminSummaryPanel title="Completed Jobs" count={completedJobsCount + programmedCompletedJobsCount} />
          </Link>
          <Link to="/admin/purchase-orders" className={classNames('dashboard-item', 'link-button')}>
            <img src="/PO Logo.png" alt="Purchase order logo" className="dashboard-logo" />
            <AdminSummaryPanel title="Purchase Orders" count={purchaseOrderCount} />
          </Link>

          {/* Conditionally render "Work Order Customer Discussion" tile */}
          {!isRestrictedUser && (
            <Link to="/admin/work-order-customer-discussion" className={classNames('dashboard-item', 'link-button')}>
              <img src="/AEN TechSupport.png" alt="Customer discussion logo" className="dashboard-logo" />
              <AdminSummaryPanel title="Work Order Customer Discussion" count={0} />
            </Link>
          )}

          {/* Conditionally render "Resource Allocation" tile */}
          {!isRestrictedUser && (
            <Link to="/admin/resource-allocation" className={classNames('dashboard-item', 'link-button')}>
              <img src="/resourceallocation.png" alt="Resource allocation logo" className="dashboard-logo" />
              <AdminSummaryPanel title="Resource Allocation" count={0} />
            </Link>
          )}
        </div>
      </div>

      <p className="centered-text">
        Having issues? Contact IT Technical Support on <span className="phone-number">0405 236 236</span>
      </p>
      <div style={{ flexShrink: 0 }}>
        <AdminFooter />
      </div>
    </div>
  );
};

export default AdminDashboard;
