// src/components/InvoicesPage.jsx

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';
import '../AdminDashboard.css';
import './InvoicesPage.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress'; // 1. Import CircularProgress

const InvoicesPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [programmedWorkOrders, setProgrammedWorkOrders] = useState([]);
  const [regularWorkOrders, setRegularWorkOrders] = useState([]);
  const [programmedPurchaseOrders, setProgrammedPurchaseOrders] = useState([]);
  const [regularPurchaseOrders, setRegularPurchaseOrders] = useState([]);
  const [invoicedProgrammedWorkOrders, setInvoicedProgrammedWorkOrders] = useState([]);
  const [invoicedRegularWorkOrders, setInvoicedRegularWorkOrders] = useState([]);

  // State Variable for User Email
  const [userEmail, setUserEmail] = useState('');
  const [isRestrictedUser, setIsRestrictedUser] = useState(false);

  // State Variables for Loading Indicators
  const [loadingProgrammedReadyToInvoice, setLoadingProgrammedReadyToInvoice] = useState(false);
  const [loadingOtherClientsReadyToInvoice, setLoadingOtherClientsReadyToInvoice] = useState(false);
  const [loadingProgrammedInvoiced, setLoadingProgrammedInvoiced] = useState(false);
  const [loadingOtherClientsInvoiced, setLoadingOtherClientsInvoiced] = useState(false);

  useEffect(() => {
    // Retrieve User Email from localStorage on Component Mount
    const email = localStorage.getItem('email');
    setUserEmail(email || '');
  }, []);

  useEffect(() => {
    // Determine if the User is Restricted
    const restrictedEmail = 'sgrehanmanagement@gmail.com';
    setIsRestrictedUser(userEmail.toLowerCase() === restrictedEmail.toLowerCase());

    // Fetch data for the selected tab
    fetchDataForTab(selectedTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail, selectedTab]);

  // Function to fetch data based on the selected tab
  const fetchDataForTab = async (tabIndex) => {
    if (tabIndex === 0) {
      // Ready to Invoice Tab
      await fetchProgrammedReadyToInvoiceData();

      if (!isRestrictedUser) {
        await fetchOtherClientsReadyToInvoiceData();
      }
    } else if (tabIndex === 1) {
      // Invoiced Tab
      await fetchProgrammedInvoicedData();

      if (!isRestrictedUser) {
        await fetchOtherClientsInvoicedData();
      }
    }
  };

  // Fetch Programmed Ready to Invoice Data
  const fetchProgrammedReadyToInvoiceData = async () => {
    setLoadingProgrammedReadyToInvoice(true);
    const token = localStorage.getItem('token');

    try {
      const [workOrdersResponse, purchaseOrdersResponse] = await Promise.all([
        fetch(`${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/status/READY_TO_INVOICE`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }),
        fetch(`${process.env.REACT_APP_API_URL}/api/purchase-orders/programmed`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }),
      ]);

      if (workOrdersResponse.ok) {
        const workOrdersData = await workOrdersResponse.json();
        setProgrammedWorkOrders(workOrdersData);
      } else {
        console.error('HTTP Error:', workOrdersResponse.status, workOrdersResponse.statusText);
        setProgrammedWorkOrders([]);
      }

      if (purchaseOrdersResponse.ok) {
        const purchaseOrdersData = await purchaseOrdersResponse.json();
        setProgrammedPurchaseOrders(purchaseOrdersData.content);
      } else {
        console.error('HTTP Error:', purchaseOrdersResponse.status, purchaseOrdersResponse.statusText);
        setProgrammedPurchaseOrders([]);
      }
    } catch (error) {
      console.error('Error fetching Programmed Ready to Invoice data:', error);
      setProgrammedWorkOrders([]);
      setProgrammedPurchaseOrders([]);
    } finally {
      setLoadingProgrammedReadyToInvoice(false);
    }
  };

  // Fetch Other Clients Ready to Invoice Data
  const fetchOtherClientsReadyToInvoiceData = async () => {
    setLoadingOtherClientsReadyToInvoice(true);
    const token = localStorage.getItem('token');

    try {
      const [workOrdersResponse, purchaseOrdersResponse] = await Promise.all([
        fetch(`${process.env.REACT_APP_API_URL}/admin/work-orders/status/READY_TO_INVOICE`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }),
        fetch(`${process.env.REACT_APP_API_URL}/api/purchase-orders`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }),
      ]);

      if (workOrdersResponse.ok) {
        const workOrdersData = await workOrdersResponse.json();
        setRegularWorkOrders(workOrdersData);
      } else {
        console.error('HTTP Error:', workOrdersResponse.status, workOrdersResponse.statusText);
        setRegularWorkOrders([]);
      }

      if (purchaseOrdersResponse.ok) {
        const purchaseOrdersData = await purchaseOrdersResponse.json();
        setRegularPurchaseOrders(purchaseOrdersData.content);
      } else {
        console.error('HTTP Error:', purchaseOrdersResponse.status, purchaseOrdersResponse.statusText);
        setRegularPurchaseOrders([]);
      }
    } catch (error) {
      console.error('Error fetching Other Clients Ready to Invoice data:', error);
      setRegularWorkOrders([]);
      setRegularPurchaseOrders([]);
    } finally {
      setLoadingOtherClientsReadyToInvoice(false);
    }
  };

  // Fetch Programmed Invoiced Data
  const fetchProgrammedInvoicedData = async () => {
    setLoadingProgrammedInvoiced(true);
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/status/INVOICED`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setInvoicedProgrammedWorkOrders(data);
      } else {
        console.error('HTTP Error:', response.status, response.statusText);
        setInvoicedProgrammedWorkOrders([]);
      }
    } catch (error) {
      console.error('Error fetching Programmed Invoiced data:', error);
      setInvoicedProgrammedWorkOrders([]);
    } finally {
      setLoadingProgrammedInvoiced(false);
    }
  };

  // Fetch Other Clients Invoiced Data
  const fetchOtherClientsInvoicedData = async () => {
    setLoadingOtherClientsInvoiced(true);
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/work-orders/status/INVOICED`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setInvoicedRegularWorkOrders(data);
      } else {
        console.error('HTTP Error:', response.status, response.statusText);
        setInvoicedRegularWorkOrders([]);
      }
    } catch (error) {
      console.error('Error fetching Other Clients Invoiced data:', error);
      setInvoicedRegularWorkOrders([]);
    } finally {
      setLoadingOtherClientsInvoiced(false);
    }
  };

  // Handle Status Change
  const handleStatusChange = async (workOrderNumber, status, isProgrammed) => {
    try {
      const token = localStorage.getItem('token');
      const apiUrl = isProgrammed
        ? `${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/status/${workOrderNumber}`
        : `${process.env.REACT_APP_API_URL}/admin/work-orders/status/${workOrderNumber}`;

      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: status }),
      });

      if (!response.ok) {
        throw new Error('Failed to update status');
      }

      // Refresh the data after status change
      fetchDataForTab(selectedTab);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  // Render Purchase Orders
  const renderPurchaseOrders = (workOrderNumber, purchaseOrders, isProgrammed) => {
    const filteredPOs = purchaseOrders.filter(po => {
      const poWorkOrderNumber = isProgrammed ? po.programmedWorkOrder?.workOrderNumber : po.workOrder?.workOrderNumber;
      return poWorkOrderNumber === workOrderNumber;
    });

    if (filteredPOs.length === 0) {
      return <div>No Purchase Orders Found</div>;
    }

    return filteredPOs.map((po, index) => (
      <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
        {index + 1}. {po.purchaseOrderNumber}
        {po.paymentStatus === 'PAID' ? (
          <CheckCircleIcon style={{ color: 'green', marginLeft: '8px' }} />
        ) : (
          <CancelIcon style={{ color: 'red', marginLeft: '8px' }} />
        )}
      </div>
    ));
  };

  // Render Work Orders
  const renderWorkOrders = (workOrders, purchaseOrders, isProgrammed) => {
    if (!Array.isArray(workOrders) || workOrders.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={5} align="left">No work orders available.</TableCell>
        </TableRow>
      );
    }

    return workOrders.map((workOrder, index) => (
      <TableRow key={index}>
        <TableCell align="left" style={{ width: '50px', border: '1px solid #ddd' }}>{index + 1}</TableCell>
        <TableCell align="left" style={{ border: '1px solid #ddd' }}>
          <Link to={`/admin/${isProgrammed ? 'programmed-work-orders' : 'work-orders'}/${workOrder.workOrderNumber}`}>
            {workOrder.workOrderNumber}
          </Link>
        </TableCell>
        <TableCell align="left" style={{ border: '1px solid #ddd' }}>
          {renderPurchaseOrders(workOrder.workOrderNumber, purchaseOrders, isProgrammed)}
        </TableCell>
        <TableCell align="center" style={{ border: '1px solid #ddd' }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#005b5b',
              color: 'white',
              padding: '4px 12px',
              fontSize: '12px',
              borderRadius: '8px',
              minWidth: '100px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              textTransform: 'none',
            }}
            onClick={() => handleStatusChange(workOrder.workOrderNumber, 'INVOICED', isProgrammed)}
          >
            Move to Invoiced
          </Button>
        </TableCell>
        <TableCell align="center" style={{ border: '1px solid #ddd' }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#d9534f',
              color: 'white',
              padding: '4px 12px',
              fontSize: '12px',
              borderRadius: '8px',
              minWidth: '100px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              textTransform: 'none',
            }}
            onClick={() => handleStatusChange(workOrder.workOrderNumber, 'NEW', isProgrammed)}
          >
            Move to Work Order
          </Button>
        </TableCell>
      </TableRow>
    ));
  };

  // Render Invoiced Work Orders
  const renderInvoicedWorkOrders = (workOrders, isProgrammed) => {
    if (!Array.isArray(workOrders) || workOrders.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={4} align="left">No work orders available.</TableCell>
        </TableRow>
      );
    }

    return workOrders.map((workOrder, index) => (
      <TableRow key={index}>
        <TableCell align="left" style={{ width: '50px', border: '1px solid #ddd' }}>{index + 1}</TableCell>
        <TableCell align="left" style={{ border: '1px solid #ddd' }}>
          <Link to={`/admin/${isProgrammed ? 'programmed-work-orders' : 'work-orders'}/${workOrder.workOrderNumber}`}>
            {workOrder.workOrderNumber}
          </Link>
        </TableCell>
        <TableCell align="center" style={{ border: '1px solid #ddd' }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#28a745',
              color: 'white',
              padding: '4px 12px',
              fontSize: '12px',
              borderRadius: '8px',
              minWidth: '150px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              textTransform: 'none',
              marginBottom: '8px',
            }}
            onClick={() => handleStatusChange(workOrder.workOrderNumber, 'READY_TO_INVOICE', isProgrammed)}
          >
            Move Back to Ready to Invoice
          </Button>
        </TableCell>
        <TableCell align="center" style={{ border: '1px solid #ddd' }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#005b5b',
              color: 'white',
              padding: '4px 12px',
              fontSize: '12px',
              borderRadius: '8px',
              minWidth: '100px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              textTransform: 'none',
            }}
            onClick={() => handleStatusChange(workOrder.workOrderNumber, 'COMPLETED_JOB', isProgrammed)}
          >
            Move to Completed
          </Button>
        </TableCell>
      </TableRow>
    ));
  };

  // Handle Tab Change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <AdminHeader />
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="invoices tabs">
          <Tab label={`Ready to Invoice`} />
          <Tab label={`Invoiced`} />
        </Tabs>

        {selectedTab === 0 && (
          <Box p={3}>
            {/* Programmed Facility Management - Ready to Invoice */}
            <h2 style={{ textAlign: 'center' }}>Programmed Facility Management - Ready to Invoice</h2>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="programmed ready to invoice table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ width: '50px', fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>#</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Work Order No</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Purchase Orders</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Move to Invoiced</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Move to Work Order</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderWorkOrders(programmedWorkOrders, programmedPurchaseOrders, true)}
                </TableBody>
              </Table>
            </TableContainer>

            {/* CircularProgress for Programmed Ready to Invoice */}
            <Box display="flex" justifyContent="center" mt={2}>
              {loadingProgrammedReadyToInvoice && <CircularProgress />}
            </Box>

            {/* Other Clients - Ready to Invoice */}
            {!isRestrictedUser && (
              <>
                <h2 style={{ marginTop: '40px', textAlign: 'center' }}>Other Clients - Ready to Invoice</h2>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="other clients ready to invoice table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" style={{ width: '50px', fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>#</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Work Order No</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Purchase Orders</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Move to Invoiced</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Move to Work Order</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {renderWorkOrders(regularWorkOrders, regularPurchaseOrders, false)}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* CircularProgress for Other Clients Ready to Invoice */}
                <Box display="flex" justifyContent="center" mt={2}>
                  {loadingOtherClientsReadyToInvoice && <CircularProgress />}
                </Box>
              </>
            )}
          </Box>
        )}

        {selectedTab === 1 && (
          <Box p={3}>
            {/* Programmed Facility Management - Invoiced */}
            <h2 style={{ textAlign: 'center' }}>Programmed Facility Management - Invoiced</h2>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="programmed invoiced table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ width: '50px', fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>#</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Work Order No</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Move Back to Ready to Invoice</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Move to Completed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderInvoicedWorkOrders(invoicedProgrammedWorkOrders, true)}
                </TableBody>
              </Table>
            </TableContainer>

            {/* CircularProgress for Programmed Invoiced */}
            <Box display="flex" justifyContent="center" mt={2}>
              {loadingProgrammedInvoiced && <CircularProgress />}
            </Box>

            {/* Other Clients - Invoiced */}
            {!isRestrictedUser && (
              <>
                <h2 style={{ marginTop: '40px', textAlign: 'center' }}>Other Clients - Invoiced</h2>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="other clients invoiced table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" style={{ width: '50px', fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>#</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Work Order No</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Move Back to Ready to Invoice</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Move to Completed</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {renderInvoicedWorkOrders(invoicedRegularWorkOrders, false)}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* CircularProgress for Other Clients Invoiced */}
                <Box display="flex" justifyContent="center" mt={2}>
                  {loadingOtherClientsInvoiced && <CircularProgress />}
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
      <AdminFooter />
    </>
  );
};

export default InvoicesPage;
